import React from "react";
import circleStripe from "../assets/images/circle-stripe.png";
import category1 from "../assets/images/course-category-1-1.png";
import category2 from "../assets/images/course-category-1-2.png";
import category3 from "../assets/images/course-category-1-3.png";
import category4 from "../assets/images/course-category-1-4.png";
import useResumeCategories from "../Hooks/useResumeCategories";
import Img from "gatsby-image";
import {Link} from "gatsby";

const CourseCatThree = () => {
  const categories = useResumeCategories()
  return (
      <section className="course-category-three">
        <img
            src={circleStripe}
            className="course-category-three__circle"
            alt=""
        />
        <div className="container">
          <div className="block-title">
            <h2 className="block-title__title">
              ¿Te interesan <br/>
              estos temas?
            </h2>
          </div>
          <div className="row">
            {categories.map(category =>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="course-category-three__single">
                    <Img fluid={{ ...category.image.localFile.sharp.fluid }} alt={category.Name} />
                  <div className="course-category-three__content">
                    <h3 className="course-category-three__title">
                      <Link to={category.slug}>{category.Name} </Link>
                    </h3>
                    {/*<p className="course-category-three__text">Over 752 Courses</p>*/}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
  );
};

export default CourseCatThree;
