import {graphql, useStaticQuery} from "gatsby"


const useResumeCategories = () => {
    const response = useStaticQuery(
        graphql`
            query resumeCategories {
                allStrapiCategories(filter: {strapiId: {in: [7,5,6,1]}}) {
                    nodes {
                        slug
                        Name
                        strapiId
                        image {
                            localFile{
                                sharp: childImageSharp {
                                    fluid( maxWidth: 270, maxHeight: 304, quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return response.allStrapiCategories.nodes;
}

export default useResumeCategories;
